<template>
  <div class="row">
    <div class="col-md-12 col-xl-12">
      <el-row :gutter="20" class="mb-g search-thong-bao">
        <el-col :xs="24" :sm="24" :md="8" :lg="6" :xl="4" class="mt-2">
          <el-input placeholder="Nhập từ khóa muốn tìm" size="medium" v-model="category"></el-input>
        </el-col>
        <el-col :xs="24" :sm="8 " :md="4" :lg="3" :xl="2" class="mt-2">
          <el-button class="w-100" type="primary" @click="searchTopic" icon="el-icon-search">Tìm kiếm</el-button>
        </el-col>
      </el-row>
      <el-skeleton style="width: 100%; height: 100%" :loading="loading" animated>
        <!--eslint-disable-->
        <template slot="template">
          <el-skeleton-item variant="div" style="width: 100%; height: 48px; margin-bottom: 1.5rem"/>
        </template>
        <template>
          <el-empty v-if="data.length === 0"
                    :description="category ? `Không tìm thấy kết quả nào!` : 'Chưa có dữ liệu cẩm nang nào!'"></el-empty>
          <mechanism-panel v-else v-for="(item, index) in data" :data="item" :key="index"
                           :text="item && item.category ? item.category : ''"></mechanism-panel>
        </template>
      </el-skeleton>
    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {INFORMATION_HANDBOOK} from "@/core/services/store/service.module";
import MechanismPanel from "@/views/pages/information/chinh-sach-panel";
import lodash from 'lodash-es';
import deepdash from 'deepdash-es';

const _ = deepdash(lodash);

export default {
  name: "cam-nang",
  components: {
    MechanismPanel
  },
  data() {
    return {
      data: [],
      loading: false,
      isFocus: false,
      category: null,
      pickerOptionsTime: {
        disabledDate: this.disabledDateTime,
      },
      defaultDate: null,
    }
  },
  methods: {
    disabledDateTime(date) {
      let tempDate = new Date();
      let currentDate = new Date(tempDate.getFullYear(), tempDate.getMonth(), tempDate.getDate(), 0, 0);
      let extentDate = this.$moment().add(2, 'days');
      return date < currentDate || date > extentDate;
    },
    queryServer(customProperties) {
      this.loading = true;
      let params = {};
      params = _.merge(params, customProperties);
      this.$store.dispatch(INFORMATION_HANDBOOK, params)
          .then((response) => {
            if (response.status === 422) {
              this.loading = false;
              this.$message({type: 'warning', message: `${response.message}`, showClose: true});
            } else {
              this.loading = false;
              this.data = response.data;
            }
          })
    },

    searchTopic: function () {
      this.queryServer({category: this.category});
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Thông tin", icon: 'far fa-info-square'},
      {title: "Cẩm nang vận hành", icon: 'far fa-info-square'}
    ]);
    this.queryServer();
  }
}
</script>
