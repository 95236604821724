<template>
  <div class="panel" v-bind:class="{
                'panel-collapsed panel-fullscreen' : isFullScreen,
                'panel-collapsed': isCollapsed,
            }">
    <div class="panel-hdr">
      <h2 class="cursor-pointer" @click="isCollapsed = !isCollapsed">
        {{ text }}
        <span v-if="isNew" class="ml-2 badge badge-warning rounded">Mới</span>
        <span v-if="isUpdate" class="ml-2 badge badge-success rounded">Cập nhật</span>
      </h2>
      <div class="panel-toolbar">
        <i class="mr-3">Cập nhật lần cuối: {{ $moment(data.updated_at).format('HH:m:s DD-MM-YYYY') }}</i>
        <i v-if="!isFullScreen" class="text-size-16 fal cursor-pointer" :class="{
                    'fa-plus-circle text-success': !isCollapsed,
                    'fa-minus-circle text-danger': isCollapsed }"
           @click="isCollapsed = !isCollapsed"></i>
          <button class="btn btn-panel bg-transparent fs-xl w-auto h-auto rounded-0" :class="{'mr-3': isFullScreen}" data-action="panel-fullscreen" data-toggle="tooltip"
                  data-offset="0,10" data-original-title="Fullscreen"
                  @click="isFullScreen = !isFullScreen">
              <i class="fal" :class="{'fa-times': isFullScreen, 'fa-expand': !isFullScreen}"></i>
          </button>
      </div>
    </div>
    <div class="panel-container collapse" ref="inside" :class="{ 'show': isCollapsed }">
      <div class="panel-content">
        <div class="demo-v-spacing">
          <el-empty v-if="!data" description="Chưa có dữ liệu nào!"></el-empty>
          <el-row v-else :gutter="10" v-html="data.content" class=" pr-5 pl-5 pt-3 pb-3 rawHTML">
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import lodash from 'lodash-es';
// import deepdash from 'deepdash-es';
// const _ = deepdash(lodash);
export default {
  name: "chinh-sach-panel",
  components: {},
  props: {
    data: Object,
    text: String
  },
  data() {
    return {
      isFullScreen: false,
      isCollapsed: false,
      isNew: false,
      isUpdate: false,
    }
  },
  methods: {},
  mounted() {
    let updated = this.data.updated_at ? this.$moment(this.data.updated_at).add(6, 'days').format('YYYY-MM-DD') : '';
    let created = this.data.created_at ? this.$moment(this.data.created_at).add(6, 'days').format('YYYY-MM-DD') : '';
    let current = this.$moment().format('YYYY-MM-DD');
    if (current <= created) {
      this.isNew = true
    } else {
      if (current <= updated) {
        this.isUpdate = true
      }
    }
  }
}
</script>
